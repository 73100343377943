import React from 'react';
import '../App.css';
import 'react-big-scheduler/lib/css/style.css'
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler'
import withDragDropContext from '../utils/withDnDContext';
import axios from 'axios';
import Utils from "../utils/tools";
import 'date-fns'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@mui/material/TextField';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import { Button, FormControl, InputLabel, Select } from '@material-ui/core';


let namespace = ""
if(process.env.REACT_APP_ENV_NAME === 'PROD')
{
    namespace = 'prod'
}
else if(process.env.REACT_APP_ENV_NAME === 'DEV')
{
    namespace = 'dev' 
}
else
{
    console.log('ENV NAME NOT RECOGNIZED: SHOULD BE PROD OR DEV => See DOCKERFILE')
    namespace = 'dev'
}

const urlHost = 'https://calendar.api.spmts.' + namespace + '.fairvision.fr/'

const urlTeam = 'https://api.infra.prod.fairvision.fr/team'

const urlChampionship = 'https://api.infra.prod.fairvision.fr/championship'

const urlClub = 'https://api.infra.prod.fairvision.fr/club/'

const urlStadium = 'https://api.infra.prod.fairvision.fr/stadium/'

const urlSpmts = 'https://calendar.core-api.spmts.' + namespace + '.fairvision.fr/spmts'

const urlStade = 'https://calendar.core-api.spmts.' + namespace + '.fairvision.fr/stade/'

const resources = [
    {
        id: 'r0',
        name: 'Terrain 1: Cap d Ail',
    },
    {
        id: 'r1',
        name: 'Terrain 2'
    },
    {
        id: 'r2',
        name: 'Terrain 3',
    },
    {
        id: 'r3',
        name: 'Terrain 4',
    },
];

const federation = [
    {
        name: "FFF"
    },
    {
        name: "CHAL"
    }
];

let teamObject = []
let championshipObject = []
let spmtsObject  = []
let configObject = []
class Calendar extends React.Component{
    constructor(props){
        super(props);
        this.schedulerData = new SchedulerData(Date.now(), ViewTypes.Week);
        this.schedulerData.localeMoment.locale('fr');
        this.schedulerData.setResources(resources);
        this.schedulerData.setMinuteStep(10)

        this.state = {
            viewModel: this.schedulerData,
            events: [],
            selectedStartHour: new Date().toISOString().slice(0,16),
            selectedEndHour: new Date().toISOString().slice(0,16),
            valueStade: resources[0]['id'],
            championship: {name: ""},
            team1: {name: ""},
            team2: {name: ""},
            club1: {},
            club2: {},
            federation: federation[0]['name'],
            spmts: {uuid: ""},
            teamsOptions: [],
            championshipOptions : [],
            spmtsOptions: [],
            configOptions: [],
            stadiumInfos: {
                id: "",
                mainDBId: "",
                name: "",
                department: ""
            },
            publish: false,
        }

        this.getStadiumInfos();
        console.log(this.state.stadiumInfos);

        this.setAuthToken(sessionStorage.getItem('token'))
        this.getEvents();
        this.getTeams();
        this.getChampionship();
        this.getSpmts();
        this.getConfigOptions();

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleStartHour = this.handleStartHour.bind(this)
        this.handleEndHour = this.handleEndHour.bind(this)
        this.handleLeague = this.handleLeague.bind(this)
        this.handleTeam1 = this.handleTeam1.bind(this)
        this.handleTeam2 = this.handleTeam2.bind(this)  
        this.handleSpmts = this.handleSpmts.bind(this)
        this.handleConfig = this.handleConfig.bind(this)
        this.handlePublish = this.handlePublish.bind(this)
    }

    setAuthToken = token => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        else
            delete axios.defaults.headers.common["Authorization"];
     }

    handleStartHour(event) {
        this.setState({selectedStartHour: event.target.value});
    };

    handleEndHour(event) {
        this.setState({selectedEndHour: event.target.value});
    };

    handleLeague(newValue) {
        this.setState({championship: newValue});
    };

    handleSpmts(newValue) {
        this.setState({spmts: newValue});
    };

    handleTeam1(newValue) {
        let that = this
        
        this.setState({category: newValue.category})
        this.setState({level: newValue.levels})
        this.setState({team1: newValue})

        axios.get(urlClub + newValue.club).then(function (response){
            that.setState({club1: response.data.data})
        }).catch(function (error) {
            console.log("Error get teams " + error)
        })
    };

    handleTeam2(newValue) {
        let that = this
        this.setState({team2: newValue})
        axios.get(urlClub + newValue.club).then(function (response){
            that.setState({club2: response.data.data})
        }).catch(function (error) {
            console.log("Error get teams " + error)
        })
    };

    handleSubmit(event) {
        this.setState({valueStade: event.target.value})
    };

    handleFederation(event) {
        this.setState({federation: event.target.value})
    };

    handleConfig(newValue) {
        this.setState({config_terrain: newValue})
    };

    handlePublish() {
        this.setState({ publish: document.getElementById("publish").checked })
    };
    
    getTeams() {
        let that = this
        axios.get(urlTeam).then(function (response) {
            teamObject = response.data.data.teams
            that.setState({ teamsOptions: teamObject})
        }).catch(function (error) {
            console.log("Error get teams " + error)
        });
    };

    getChampionship() {
        let that = this
        axios.get(urlChampionship).then(function (response) {
            championshipObject = response.data.data.championships
            that.setState({ championshipOptions: championshipObject })
        }).catch(function (error) {
            console.log('Error get championships ' + error)
        })
    };

    async getConfigOptions() {
        const stadiumId = await new Promise((resolve) => {
            // Wait for the stadium_id value to be available in sessionStorage
            const interval = setInterval(() => {
                const idStade = this.state.stadiumInfos.id;
                if (idStade) {
                    clearInterval(interval);
                    resolve(idStade);
                }
            }, 100); // Check every 100ms, you can adjust this value as needed
        });

        const response = await axios.get(urlStade + stadiumId);
        configObject = response.data.data.config_terrain;
        this.setState({ configOptions: configObject });
    }

    async getEvents() {
        const stadiumId = await new Promise((resolve) => {
            // Wait for the stadium_id value to be available in sessionStorage
            const interval = setInterval(() => {
                const idStade = this.state.stadiumInfos.id;
                if (idStade) {
                    clearInterval(interval);
                    resolve(idStade);
                }
            }, 100); // Check every 100ms, you can adjust this value as needed
        });
        const response = await axios.get(urlHost + 'events/' + stadiumId);

        this.setState({ events: response.data.events });
        this.schedulerData.setEvents(response.data.events);
        this.forceUpdate();
    }

    async getStadiumInfos() {
        const username = await new Promise((resolve) => {
            // Wait for the stadium_id value to be available in sessionStorage
            const interval = setInterval(() => {
                const idStade = sessionStorage.getItem('username');
                if (idStade) {
                    clearInterval(interval);
                    resolve(idStade);
                }
            }, 100); // Check every 100ms, you can adjust this value as needed
        });

        const responseId = await axios.get(urlHost + 'stade/' + username);
        this.state.stadiumInfos.id = responseId.data;

        const responseMainDb = await axios.get(urlStade + this.state.stadiumInfos.id);
        this.state.stadiumInfos.mainDBId = responseMainDb.data.data.id_main_db;;

        const responseInfos = await axios.get(urlStadium + this.state.stadiumInfos.mainDBId );
        this.state.stadiumInfos.name = responseInfos.data.data.name;
        this.state.stadiumInfos.department = responseInfos.data.data.id_department;
    }

    getSpmts(){
        let that = this
        axios.get(urlSpmts).then(function (response){
            spmtsObject = response.data.data.spmts
            that.setState({spmtsOptions: spmtsObject})
        }).catch(function (error) {
            console.log("Error get spmts " + error)
        });
    };

    addEvent(event){
        axios({
            method: 'POST',
            url: urlHost + "event/" + event.id,
            headers: { 'content-type': 'application/json' },
            data: event
        });
    };

    changeEvent(event){
        axios({
            method: 'PUT',
            url: urlHost + "event/" + event.id,
            headers: { 'content-type': 'application/json' },
            data: event
        });
    };

    deleteEvent(event){
        axios({
            method: 'DELETE',
            url: urlHost + "event/" + event.id,
            headers: { 'content-type': 'application/json' },
            data: event
        });
    };
    behaviors

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.events);
        this.setState({
            viewModel: schedulerData
        })
    };

    eventClicked = (schedulerData, event) => {
    };

    addToCalendar(data){
        let newEventCalendar = {
            id: Utils.generateId(),
            title: `${this.state.team1.name} =VS= ${this.state.team2.name}`,
            start: this.state.selectedStartHour,
            end: this.state.selectedEndHour,
            resourceId: this.state.valueStade,
            bgColor: 'purple',
            eventPass: '0',
            process: '0',
            data: data,
            id_stade: this.state.stadiumInfos.id
        }
        console.log("New event", newEventCalendar)
        this.addEvent(newEventCalendar);
        this.state.viewModel.addEvent(newEventCalendar);

        this.forceUpdate();
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let newEvent = {
            id: Utils.generateId(),
            title: 'Evenement d`enregistrement',
            start: start,
            end: end,
            resourceId: slotId,
            bgColor: 'purple',
            eventPass: '0',
        };

        this.addEvent(newEvent);
        schedulerData.addEvent(newEvent);
        this.setState({
            viewModel: schedulerData
        })
    };

    moveEvent = (schedulerData, event, slotId, slotName, start, end )=> {
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Modifier l'evenement ? eventTitle: ${event.title}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
            this.changeEvent(event);
            schedulerData.moveEvent(event, slotId, slotName, start, end);
            this.setState({
                viewModel: schedulerData
            })
        }
    };

    viewEvent2Click = (event) => {
        alert(`Match ${this.state.team1.name_trunc} VS ${this.state.team2.name_trunc}`);
    };

    viewMountingInfo = () => {
        const date_opt = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let nametmp = this.state.team1.name_trunc + "-" + this.state.team2.name_trunc

        let datetmp = this.state.selectedStartHour
        let year = datetmp.slice(0,4)
        let month = datetmp.slice(5,7)
        let day = datetmp.slice(8,10)
        let hour = datetmp.slice(11, 13)
        let min = datetmp.slice(14, 16)

        let date_str = new Date(Date.UTC(parseInt(year), parseInt(month)-1, parseInt(day), hour, min, 0)) 
        let start = this.state.selectedStartHour.substring(this.state.selectedStartHour.indexOf('T')+1) + ":00"
        let end = this.state.selectedEndHour.substring(this.state.selectedEndHour.indexOf('T')+1) + ":00"

        let mountingBaseJson = {
            "name":  nametmp.replace(/\s/g, '_'),
            "championship": this.state.championship.id,
            "team_1": this.state.team1.name_trunc,
            "team_2": this.state.team2.name_trunc,
            "logo_1": this.state.club1.url_logo.split("/").pop(),
            "logo_2": this.state.club2.url_logo.split("/").pop(),
            "sigle_1": this.state.team1.acronym,
            "sigle_2": this.state.team2.acronym,
            "league": this.state.federation,
            "league_name": this.state.championship.name + " " + this.state.championship.category,
            "id_1": this.state.team1.id,
            "id_2": this.state.team2.id, 
            "date_string": date_str.toLocaleDateString('fr-FR', date_opt),
            "date": date_str,  
            "start": start,
            "end": end,
            "stadium": this.state.stadiumInfos.mainDBId,
            "department": this.state.stadiumInfos.department,
            "place": this.state.stadiumInfos.name,
            //"spmts": this.state.spmts.uuid,
            "config_cam": this.state.config_terrain.config,
            "cam_pos": this.state.config_terrain.position,
            "discord_webhook": this.state.config_terrain.discord_webhook,
            "publish": this.state.publish,
        };

        console.log("MOUNTING JSON : ", mountingBaseJson)
        console.log("JSON STRINGIFY => ", JSON.stringify(mountingBaseJson))
        return mountingBaseJson;
    };

    removeEvent = (schedulerData, event) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm(`Suppr l'evenement ? eventTitle: ${event.title}`)) {
            this.deleteEvent(event);
            schedulerData.removeEvent(event);
            this.setState({
                viewModel: schedulerData
            })
        }
    }

  render() {
    return (
      <div>
          <Scheduler schedulerData={this.state.viewModel}
                     prevClick={this.prevClick}
                     nextClick={this.nextClick}
                     onSelectDate={this.onSelectDate}
                     onViewChange={this.onViewChange}
                     eventItemClick={this.eventClicked}
                     newEvent={this.newEvent}
                     moveEvent={this.moveEvent}
                     viewEventClick={this.removeEvent}
                     viewEventText="Delete"
                     viewEvent2Text="Info"
                     viewEvent2Click={this.viewEvent2Click}
                     viewMountingInfo={this.viewMountingInfo}
                     eventItemTemplateResolver={this.eventItemTemplateResolver}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                <FormControl>
                    <InputLabel style={{margin: '5px 25px'}} htmlFor="age-native-simple">Stade</InputLabel>
                    <Select
                    style={{margin: '33px 25px'}}
                    native
                    value={this.state.valueStade}
                    onChange={(item) => {this.handleSubmit(item)}}
                    >
                        {resources.map(({id, name}) =>  <option value={id}>{name}</option>)}
                    </Select>
                </FormControl>
                <form noValidate>
                    <TextField
                    style={{margin: '16px'}}
                    id="Start datetime"
                    label="Start match"
                    type="datetime-local"
                    defaultValue={this.state.selectedStartHour}
                    onChange={this.handleStartHour}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    />
                </form>
                <form noValidate>
                    <TextField
                    style={{margin: '16px'}}
                    id="datetime-local"
                    label="End match"
                    type="datetime-local"
                    defaultValue={this.state.selectedEndHour}
                    onChange={this.handleEndHour}
                    InputLabelProps={{
                    shrink: true,
                    }}
                    />
                </form>
                <FormControl>
                    <InputLabel style={{margin: '15px 55px'}} htmlFor="age-native-simple">Federation</InputLabel>
                    <Select
                    style={{margin: '33px 55px'}}
                    native
                    value={this.state.federation}
                    onChange={(item) => {this.handleFederation(item)}}
                    >
                        {federation.map(({name}) =>  <option value={name}>{name}</option>)}
                    </Select>
                </FormControl>
                <Autocomplete
                id="championship"
                getOptionLabel={(option) => option.name + ' ' + option.category}
                options={this.state.championshipOptions}
                renderOption={(props, option) => ( <p>{props.name} {props.category}</p>)}
                onChange={(event, value) => {this.handleLeague(value)}}
                renderInput={(params) => <TextField {...params} style={{width: 250, margin: '16px'}} label="Championship" />}
                />
                <Autocomplete
                id="team1"
                getOptionLabel={(option) => option.name}
                options={this.state.teamsOptions}
                renderOption={(props, option) => ( <p>{props.name} {props.levels}</p>)}
                onChange={(event, item) => {this.handleTeam1(item)}}
                renderInput={(params) => <TextField {...params} style={{width: 250, margin: '16px'}} label="Team 1" />}
                />
                <Autocomplete
                id="team2"
                getOptionLabel={(option) => option.name}
                options={this.state.teamsOptions}
                renderOption={(props, option) => ( <p>{props.name} {props.levels}</p>)}
                onChange={(event, item) => {this.handleTeam2(item)}}
                renderInput={(params) => <TextField {...params} style={{width: 250, margin: '16px'}} label="Team 2" />}
                />
                {/* CHOIX DU SPMTS (INUTILE SI ON FONCTIONNE PAR CONFIG, MAIS ON SAIT JAMAIS)
                <Autocomplete
                id="spmts"
                getOptionLabel={(option) => option.uuid}
                options={this.state.spmtsOptions}
                renderOption={(props, option) => ( <p>{props.uuid}</p>)}
                onChange={(event, value) => {this.handleSpmts(value)}}
                renderInput={(params) => <TextField {...params} style={{width: 480, margin: '16px'}} label="Spmts" />}
                />
                */}
                <Autocomplete
                id="config_terrain"
                getOptionLabel={(option) => option.name}
                options={this.state.configOptions}
                renderOption={(props, option) => (<p>{props.name}</p>)}
                onChange={(event, value) => { this.handleConfig(value) }}
                renderInput={(params) => <TextField {...params} style={{ width: 250, margin: '16px' }} label="Configuration" />}
                />
                    <div><input type="checkbox" 
                    style={{ margin: '40px 10px' }}
                    id="publish" 
                    name="publish"
                    onChange={this.handlePublish}/>
                    <label for="publish">Publier</label>
                    </div>
            </Grid>
        </MuiPickersUtilsProvider>
        <div> 
            <div style={{
                    paddingTop: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
            }}>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {
                    let data = this.viewMountingInfo()
                    this.addToCalendar(data)
                }}
            >
            Add to Calendar
            </Button>
            </div>
        </div>
      </div>
    )
  }
}

export default withDragDropContext(Calendar);
